<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
      <li class="breadcrumb-item">{{breadcrumbA}}</li>
      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
      </ol>
    </nav>
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
        <h6 class="card-title">{{breadcrumbB}}</h6>
        <p class="card-description"> {{descripcion}}</p>
        </div>  
      </div>
      </div>
    </div> 

    <lista-carga v-if="triggers.load"></lista-carga>
    <div class="row" v-else>
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">



                <div class="row mb-3">
                    <div class="col">
                        <label class="mr-sm-2">Desde</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='desde'/>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Hasta</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='hasta'/>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2 w-100" for="">&nbsp;</label>
                        <button  class="btn btn-md btn-success w-100" id="buscar">Consultar Requisitorias</button>
                    </div>
                </div>
          


            <div class="table-responsive">
               <table
                id="novedades"
                class="table table-theme table-row v-middle"
                data-plugin="dataTable"
              >




                <thead>
                  <tr>
                    <th><span class="text-muted">Status</span></th>
                    <th><span class="text-muted">Auth</span></th>
                    <th><span class="text-muted">Opción</span></th>
                    
                    <!--<th><span class="text-muted">Hora</span></th>-->
                    <th><span class="text-muted">Legajo</span></th>

                    
                    <th><span class="text-muted">Nombre</span></th>
                    <th><span class="text-muted">Basico Promedio</span></th>
                    <th><span class="text-muted">Categoría</span></th>
                    <th><span class="text-muted">Motivo</span></th>
                    <th><span class="text-muted">Detalle</span></th>
                    <th><span class="text-muted">Operación id</span></th>
                    <th><span class="text-muted">Operación Nombre</span></th>
                    <th><span class="text-muted">Solicitado por</span></th>
                    <th><span class="text-muted">Fecha de solicitud</span></th>
                    
                    <th><span class="text-muted">Turno</span></th>

                    <th><span class="text-muted">Observado</span></th>
                  
                    <th><span class="text-muted">Accion</span></th>

                   
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="caso in novedades" :key="caso.legajo">
                        

                        <td v-if="caso.statusDirectorio==0">
                            <span  class="badge text-uppercase badge-warning">
                             En Espera
                            </span>
                        </td>

                        <td v-if="caso.statusDirectorio=='1'">
                            <span  class="badge text-uppercase badge-success">
                             Aprobado
                            </span>
                        </td>

                       

                        <td v-if="caso.statusDirectorio=='2'">
                            <span  class="badge text-uppercase badge-danger">
                             Observado
                            </span>
                        </td>


                        <td v-if="caso.statusDirectorio=='3'">
                            <span  class="badge text-uppercase badge-danger">
                             Rechazado
                            </span>
                        </td>


                       


                        <td align="center">
                            
                             {{caso.auth}}
                            
                        </td>
                      


                        <td class="text-sm text-wrap">{{ caso.opcion }}</td>

                        
                        <!--<td class="text-sm text-wrap">{{ caso.hora }}</td>-->
                        <td class="text-sm text-wrap">{{ caso.legajo }}</td>
                        
                        <td class="text-sm text-wrap">{{ caso.nombre }}</td>
                        <td class="text-sm text-wrap" align="center">{{ caso.basico }}</td>
                        <td class="text-sm text-wrap">{{ caso.categoria }}</td>

                        <td class="text-sm text-wrap">{{ caso.motivo }}</td>
                   
                       <td class="text-sm text-wrap">{{ caso.razon }}</td>
                        
                        <td class="text-sm text-wrap">{{ caso.operacion }}</td>
                        <td class="text-sm text-wrap">{{ caso.operacion_name }}</td>
                        <td class="text-sm text-wrap">{{ caso.responsable_nombre }}</td>
                        <td :id="'key'+caso.fireId">{{ caso.fecha }}</td>
                       
                        <td class="text-sm text-wrap">{{ caso.turno }}</td>

                       

                        <td class="text-sm text-wrap comentario" v-if="caso.observacion=='1'"> 

                          <i :data-fire="caso.fireId" onclick="localStorage.setItem('status_id',$(this).data('fire'))" class="feather icon-mail" @click="mensajes()" style="font-size: 20px;color: green;"></i>

                          <i v-if="caso.noti=='1'" :id="'key'+caso.fireId" class="feather icon-radio" style="font-size: 20px;color: red;"><span style="visibility:hidden;font-size:1px;">Sin responder</span></i>
                          <i v-else></i>

                        </td>
                        
                        <td class="text-sm text-wrap" v-else> - </td>
                        <td>
                           
                          

                          <select v-if="isEditor('Novedades')==true"  class="custom-select mr-3 resolucionSelect" @change="cambiarStatus($event, caso)">
                                <option value="0" :selected="caso.status == 'En espera'">En espera</option>
                                <option value="1"  :selected="caso.status == 'Resuelto'">Aprobado</option>
                                <!--<option value="2" :selected="caso.status == 'Observado'">Observado</option>-->
                                <option value="3" :selected="caso.status == 'Rechazado'">Rechazado</option>
                                
                            </select>

                            <select v-else class="custom-select mr-3 resolucionSelect" @change="cambiarStatus($event, caso)" disabled="true">
                                 <option value="1" :selected="caso.status == 'Resuelto'">Aprobado</option>
                                <!--<option value="2" :selected="caso.status == 'Observado'">Observado</option>-->
                                <option value="3" :selected="caso.status == 'Rechazado'">Rechazado</option>
                            </select>
                        </td>


                     



                    </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



 <!-- Extra large modal -->


<div class="modal fade observado_modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="card-body card-block">
        <div class="row">
          <div class="col-sm-12" style="background-color: #f9e8b7;padding: 10px;">
            
              

              <div class="form-group">
                    <label for="exampleFormControlTextarea1">Escribe la observación/detalle que explique el motivo del estado</label>
                    <textarea class="form-control" id="observacion" rows="5"></textarea>
                  </div>
           
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-sm-6"><button class="btn btn-block btn-success" v-on:click="guardaObservacion()">Guardar</button></div>
              <div class="col-sm-6"><button  class="btn btn-block btn-danger" data-dismiss="modal" id="cerrar_modal">Cancelar</button></div>
            </div>  
          </div>
        </div>
      </div>

        <div id="historial"></div>


    </div>
  </div>
</div>





</template>

<script>
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";
require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();
require("../../../public/assets/css/data-table-estilos-propios.css");
const userData = JSON.parse(sessionStorage.getItem('userData'));

import firebase from "firebase/app";
import "firebase/database";

import ListaCarga from '../ListaCarga';

const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    name: "novedades",
    components: {
        ListaCarga
    },
    data: () => ({
        breadcrumbA: "Inicio",
        breadcrumbB: "Bandeja de entrada Directorio",
        descripcion: "Bandeja de entrada de requisitorias de personal ( Nuevo )",
        novedades: [],
        zonales: [],
        triggers: {load:true},

    }),
    methods: {

        b64toBlob(b64, onsuccess, onerror) {
            var img = new Image();

            img.onerror = onerror;

            img.onload = function onload() {
                var canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;

                var ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                canvas.toBlob(onsuccess);
            };

            img.src = b64;
        },

        openWindow(key) {




            $.ajax({
                type: 'get',
                url: 'https://aplicativos.firebaseio.com/novedades_usuarios_test/' + key + '.json?print=pretty',
                contentType: "application/json; charset=utf-8",
                traditional: true,
                success: function(datos) {


                    if (datos.certificado.includes("base64")) {

                        fetch(datos.certificado)
                            .then(response => response.blob())
                            .then(function(myBlob) {
                                var objectURL = URL.createObjectURL(myBlob);
                                window.open(objectURL, '_blank');
                            });

                    } else {
                        window.open(datos.certificado, '_blank');
                    }


                }

            });




        },

        openWindow_a(key) {


            console.log(key)


            $.ajax({
                type: 'get',
                url: 'https://aplicativos.firebaseio.com/novedades_usuarios_test/' + key + '/certificado_update.json?orderBy="$key"&limitToLast=1&print=pretty',
                contentType: "application/json; charset=utf-8",
                traditional: true,
                success: function(datos) {

                    $.each(datos, function(clave, valor) {

                        if (valor.certificado.includes("base64")) {

                            fetch(valor.certificado)
                                .then(response => response.blob())
                                .then(function(myBlob) {
                                    var objectURL = URL.createObjectURL(myBlob);
                                    window.open(objectURL, '_blank');
                                });

                        } else {
                            window.open(valor.certificado, '_blank');
                        }

                    })




                }

            });




        },

        isEditor(modulo) {

            if (userPermission.find(record => record.ModulosAccess === modulo)) {
                var status = userPermission.find(record => record.ModulosAccess === modulo).Editor;
                console.log(modulo + ' : ' + status)
                return status;
            } else {
                console.log(modulo + ' : ' + false)
                return false;
            }
        },


        getNovedad() {


            this.novedades = [];
            this.triggers.load = true;


             axios.get("https://consumos.foodservice.com.ar/api/empresas")
                .then((response) => {

                    $.each(response.data,function(clave,value){

                        localStorage.setItem("op-"+value.id,value.name)
                        localStorage.setItem("apropiacion-"+value.id,value.centroapropiacion_id)
                    });

                });


                   axios.get("https://aplicativos.firebaseio.com/categorias.json")
                .then((response) => {

                    $.each(response.data,function(clave,value){

                        localStorage.setItem("cat-"+value.codigo,value.nombre)
                    });

                });

  //axios.get("https://aplicativos.firebaseio.com/requisitorias.json?orderBy=%22depto%22&equalTo=%22Directorio%22&print=pretty")
            axios.get("https://auth.foodservice.com.ar/?type=requi_direccion&access_token=1234567")
                .then((response) => {
                    var vue = this;
                    var obs = 0;




                    $.each(response.data, (id, item) => {
                        let caso = item;

                        var certif_u = '-';
                        if (caso != null) {

                            if (caso.observacion != undefined) {

                                obs = 1;
                            } else {
                                obs = 0;
                            }




                            if (caso.certificado_update != undefined) {

                                $.each(caso.certificado_update, function(clab, itb) {

                                    certif_u = itb.certificado;



                                });

                                cert_c = certif_u;
                            }




                            const reemplazarIndefinido = (string) => {
                                if (string == undefined || string == null || string == '') {
                                    return "-"
                                }
                                return string
                            }



                            var fechaInicio = new Date(caso.desde).getTime();
                            var fechaFin = new Date(caso.hasta).getTime();

                            var diff = fechaFin - fechaInicio;
                            var bejerman = "-";
                            
                            if (caso.bejerman_id) {
                                bejerman = caso.bejerman_id;
                            }

                            var stat = 0;
                            if (caso.statusLegales==null) {
                                stat = 0;
                            }else{
                                stat=caso.statusGerencia;
                            }


                            const status_ok = () => {
                                
                                var status = 0;

                                $.each(caso.status_historial,function(clave,valor){

                                     if (valor.depto === "Costos" ) {
                                    

                                   
                                        status = 1;
                                         

                                    }

                                })
                               

                                
                                return status
                            }

                                    var categoria = reemplazarIndefinido(caso.categoria);

                                    if (caso.opcion=="Reemplazo") {
                                        var cat = localStorage.getItem(caso.legajo);
                                        categoria = localStorage.getItem("cat-"+cat);
                                    }


                                    var centro_apropiacion=caso.operacion_id;
                                    console.log("centro: "+localStorage.getItem("apropiacion-"+caso.operacion_id))
                                    if (localStorage.getItem("apropiacion-"+caso.operacion_id)!=null) {
                                        centro_apropiacion=localStorage.getItem("apropiacion-"+caso.operacion_id);
                                    }


                                    var centro_nombre=caso.operacion_nombre;

                                    if (localStorage.getItem("op-"+caso.operacion_id)!=null) {
                                        centro_nombre=localStorage.getItem("op-"+caso.operacion_id);
                                    }


                                 const getBasico = (id)=>{

                                    const formatoMoneda = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' });


                                        var basico="";
                                        $.each(JSON.parse(sessionStorage.getItem('personal')),function(clave,valor){


                                            if (valor.cat_codigo==id) {
                                                
                                               if ('leg_fecegr' in valor) {
                                                  
                                                }else{
                                                      basico=formatoMoneda.format(valor.leg_basico);
                                                }



                                                
                                                
                                            
                                            }

                                            


                                        })

                                        return basico;

                                 }   


                                 if (caso.status==1) {


                                        if (caso.status_ajuste==undefined) {

                                            vue.novedades.push({
                                                fireId: id,
                                                fecha: caso.fecha,
                                                hora: caso.hora,
                                                legajo: caso.legajo,
                                                motivo: caso.motivo,
                                                nombre: caso.nombre,
                                                opcion: caso.opcion,
                                                operacion: centro_apropiacion,
                                                operacion_name:centro_nombre,
                                                responsable_dni:caso.responsable_dni,
                                                responsable_nombre:caso.responsable_nombre,
                                                razon:caso.razon,
                                                categoria:reemplazarIndefinido(categoria),
                                                turno:reemplazarIndefinido(caso.turno),
                                                status: status_ok(),
                                                statusDirectorio: caso.statusDirectorio,
                                                auth: reemplazarIndefinido(caso.auth),
                                                basico: getBasico(localStorage.getItem(reemplazarIndefinido(categoria)))
                                       
                                             });

                                        }else{

                                             vue.novedades.push({
                                                fireId: id,
                                                fecha: caso.fecha,
                                                hora: caso.hora,
                                                legajo: caso.legajo,
                                                motivo: caso.motivo,
                                                nombre: caso.nombre,
                                                opcion: caso.opcion,
                                                operacion: centro_apropiacion,
                                                operacion_name:centro_nombre,
                                                responsable_dni:caso.responsable_dni,
                                                responsable_nombre:caso.responsable_nombre,
                                                razon:"Ajustar salario basico de "+caso.mes_ajuste+" de $"+caso.salario_pasado+" a $"+caso.salario_nuevo+"",
                                                categoria:reemplazarIndefinido(categoria),
                                                turno:reemplazarIndefinido(caso.turno),
                                                status: status_ok(),
                                                statusDirectorio: caso.statusDirectorio,
                                                auth: reemplazarIndefinido(caso.auth),
                                                basico: "-"
                                       
                                             });



                                        }

                                 }




                        }
                    });



                })
                .then(() => {
                  this.triggers.load = false;
                  this.$nextTick(()=>{
                    // $('#novedades').dataTable.fnDestroy();
  
  
  
                      $('#novedades').DataTable({
                          "language": {
                              "decimal": ",",
                              "thousands": ".",
                              "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                              "infoEmpty": "Mostrando registros del0 al0 de un total de0 registros",
                              "infoPostFix": "",
                              "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                              "loadingRecords": "Cargando...",
                              "lengthMenu": "Mostrar _MENU_ registros",
                              "paginate": {
                                  "first": "Primero",
                                  "last": "Último",
                                  "next": "Siguiente",
                                  "previous": "Anterior"
                              },
                              "processing": "Procesando...",
                              "search": "Buscar:",
                              "searchPlaceholder": "Término de búsqueda",
                              "zeroRecords": "No se encontraron resultados",
                              "emptyTable": "Ningún dato disponible en esta tabla",
                              "aria": {
                                  "sortAscending": ": Activar para ordenar la columna de manera ascendente",
                                  "sortDescending": ": Activar para ordenar la columna de manera descendente"
                              },
                          },
                          "scrollX": false,
                          "search": {
                              regex: true
                          },
                          dom: 'Bfrtip',
                          responsive: false,
                          nowrap: false,
                          "initComplete": function(settings, json) {
  
                              setTimeout(function() {
                                  console.log("completo");
                                  $('#novedades').DataTable().order([0, 'desc']).draw();
                              }, 2000)
  
  
                              // call your function here
                          },
                          "order": [
                                    [0, "desc"]
                                ],
                          orientation: 'landscape',
                          buttons: ['print', 

                                                {
                                                extend: 'pdf',
                                                orientation: 'landscape',
                                                pageSize: 'LEGAL',
                                                customize: function(doc) {
                                                      doc.defaultStyle.fontSize = 7; //<-- set fontsize to 16 instead of 10 
                                                }  
                                              },

                                           {
                                  text: 'TODOS',
                                  className: 'btn btn-info',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("").draw();
                                     
                                  }
                              },
                                  {
                                  text: 'EN ESPERA',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("EN ESPERA").draw();
                                     
                                  }
                              },
                              {
                                  text: 'APROBADO',
                                  className: 'btn btn-success',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("APROBADO").draw();
                                      
                                  }
                              },
                              {
                                  text: 'RECHAZADO',
                                  className: 'btn btn-danger',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("RECHAZADO").draw();
                                      
                                  }
                              },
                              {
                                  text: 'AJUSTE SALARIAL',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(2).search("AJUSTE SALARIAL").draw();
                                      
                                  }
                              }
                                ]
                      })
  
  
  
                      const diasEntreFechas = (desde, hasta) => {
  
                          var dia_actual = desde;
                          var fechas = [];
                          while (dia_actual.isSameOrBefore(hasta)) {
                              fechas.push(dia_actual.format('YYYY-MM-DD'));
                              dia_actual.add(1, 'days');
                          }
                          return fechas;
                      };
  
                      $("#buscar").click(function() {
  
                          console.log($("#desde").val() + "hasta " + $("#hasta").val())
                          var filtro = "";
                          var desde = moment($("#desde").val());
                          var hasta = moment($("#hasta").val());
                          var results = diasEntreFechas(desde, hasta);
  
  
                          $.each(results, function(key, value) {
                              filtro += value + "|";
                          })
                          var table = $('#novedades').DataTable();
                          console.log(filtro.substring(0, filtro.length - 1));
                          table.column(10).search(filtro.substring(0, filtro.length - 1), true, false).draw();
  
  
                      })

                  })


                })

        },



        cambiarStatus(event, caso) {
            console.log("datos")
            
            console.log(event)
            console.log(caso)

            localStorage.setItem("event",JSON.stringify(event))
            localStorage.setItem("caso",JSON.stringify(caso))

            const addZero=(i)=>{
                if (i < 10) {
                    i = "0" + i;
                }
                return i;
            }


            var h = new Date();
            var hora = addZero(h.getHours());
            var minutos = ("0" + h.getMinutes()).substr(-2);
            var segundos = ("0" + h.getSeconds()).substr(-2);
            var horaActual = hora + ":" + minutos + ":" + segundos;

            var hoy = new Date();
           
            var m = hoy.getMonth() + 1;
            var mes = m < 10 ? "0" + m : m;
            var dia = hoy.getDate();
            dia = dia < 10 ? "0" + dia : dia;
            var fechaActual = hoy.getFullYear() + "-" + mes + "-" + dia;

            var nombreCompleto = userData.leg_nombre + ' ' + userData.leg_apellido;

                            // Dividir el nombre completo en palabras separadas
                var palabras = nombreCompleto.split(" ");

                // Capturar la primera letra del nombre
                var primeraLetraNombre = palabras[0].charAt(0);

                // Capturar la última palabra como el apellido
                var apellido = palabras[palabras.length - 1];

                // Capturar la primera letra del apellido
                var primeraLetraApellido = apellido.charAt(0);
             
            caso.auth=primeraLetraNombre + '' + primeraLetraApellido;

            if (event.target.value == 2 || event.target.value == 3) {

                window.$('.observado_modal').modal();
                localStorage.setItem("status_id", caso.fireId);
                localStorage.setItem("status_sms", event.target.value);

                caso.statusLegales = event.target.value;
                caso.statusDirectorio = event.target.value;




                $("#historial").empty();

            } else if (event.target.statusDirectorio == "Editar") {
                window.$('.editar_modal').modal();
                localStorage.setItem("status_id", caso.fireId);
                localStorage.setItem("status_sms", event.target.value);


            } else if (event.target.value == "") {


            } else {

                
               
                        if (caso.opcion=="Ajuste Salarial") {


                            firebase.database().ref('ajuste_salarial/' + caso.fireId+'/status_historial').push({
                                    
                                    status: event.target.value,
                                    fecha: fechaActual,
                                    hora:horaActual,
                                    usuario_nombre: userData.leg_nombre + ' ' + userData.leg_apellido,
                                    usuario_id:userData.leg_numdoc,
                                    depto:"Directorio"


                                })
                                .then(() => {
                                            firebase.database().ref('ajuste_salarial/' + caso.fireId).update({
                                            
                                            statusDirectorio: event.target.value,
                                            depto_destino:"PLATAFORMA DE COSTOS",
                                            auth:primeraLetraNombre + '' + primeraLetraApellido
                                            


                                        })
                                        .then(() => {
                                            caso.status = event.target.value;
                                            caso.statusMensaje = event.target.value;
                                            caso.statusDirectorio=event.target.value;
                                            //$("#key" + caso.fireId).hide();
                                        })
                                        .catch(e => {
                                            console.log(e);
                                        });
                                })
                                .catch(e => {
                                    console.log(e);
                                });



                        }else{

                            firebase.database().ref('requisitorias/' + caso.fireId+'/status_historial').push({
                                    
                                    status: event.target.value,
                                    fecha: fechaActual,
                                    hora:horaActual,
                                    usuario_nombre: userData.leg_nombre + ' ' + userData.leg_apellido,
                                    usuario_id:userData.leg_numdoc,
                                    depto:"Directorio"


                                })
                                .then(() => {
                                            firebase.database().ref('requisitorias/' + caso.fireId).update({
                                            
                                            statusDirectorio: event.target.value,
                                            depto_destino:"Seleccion",
                                            auth:primeraLetraNombre + '' + primeraLetraApellido
                                            


                                        })
                                        .then(() => {
                                            caso.status = event.target.value;
                                            caso.statusMensaje = event.target.value;
                                            caso.statusDirectorio=event.target.value;
                                            //$("#key" + caso.fireId).hide();
                                        })
                                        .catch(e => {
                                            console.log(e);
                                        });
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                        }
            }
        },




        guardaObservacion() {

            var event = JSON.parse(localStorage.getItem("event"));
            var caso = JSON.parse(localStorage.getItem("caso"));

            const addZero = (i) => {
                if (i < 10) {
                    i = "0" + i;
                }
                return i;
            }

            var h = new Date();
            var hora = addZero(h.getHours());
            var minutos = ("0" + h.getMinutes()).substr(-2);
            var segundos = ("0" + h.getSeconds()).substr(-2);
            var horaActual = hora + ':' + minutos + ':' + segundos;

            var today = new Date();
            var m = today.getMonth() + 1;
            var mes = m < 10 ? "0" + m : m;
            var dia = today.getDate();
            dia = dia < 10 ? "0" + dia : dia;
            var fechaActual = today.getFullYear() + "-" + mes + "-" + dia;
            var timestamp = fechaActual + " " + horaActual;

                var nombreCompleto = userData.leg_nombre + ' ' + userData.leg_apellido;

                            // Dividir el nombre completo en palabras separadas
                var palabras = nombreCompleto.split(" ");

                // Capturar la primera letra del nombre
                var primeraLetraNombre = palabras[0].charAt(0);

                // Capturar la última palabra como el apellido
                var apellido = palabras[palabras.length - 1];

                // Capturar la primera letra del apellido
                var primeraLetraApellido = apellido.charAt(0);
             
                caso.auth=primeraLetraNombre + '' + primeraLetraApellido;

               firebase.database().ref('requisitorias/' + localStorage.getItem('status_id') + '/observacion').push({
                    detalle: $("#observacion").val(),
                    timestamp: timestamp,
                    nombre: userData.leg_nombre + ' ' + userData.leg_apellido

                })
                .then(() => {


                     firebase.database().ref('requisitorias/' + localStorage.getItem('status_id')+'/status_historial').push({
                        
                        status:3,
                        fecha: fechaActual,
                        hora:horaActual,
                        usuario_nombre: userData.leg_nombre + ' ' + userData.leg_apellido,
                        usuario_id:userData.leg_numdoc,
                        depto:"Directorio"


                    })
                    .then(() => {



                                firebase.database().ref('requisitorias/' + localStorage.getItem('status_id')).update({
                                        status: 3,
                                         auth:primeraLetraNombre + '' + primeraLetraApellido,
                                         statusDirectorio:3

                                        

                                    })
                                    .then(() => {

                                        caso.statusDirectorio=3;
                                           
                                        
                                    })
                                    .catch(e => {
                                        console.log(e);
                                    });


                        })
                        .catch(e => {
                            console.log(e);
                        });



                    $("#observacion").val("");

                    Swal.fire(
                        "Observación creada correctamente!",
                         "Su observación fue enviada a quien que corresponde",
                        "success"
                    );

                    setTimeout(function() {
                        $("#cerrar_modal").click();
                        


                        setTimeout(function() {

                            localStorage.removeItem('status_id');

                        }, 3000);

                    }, 1000);



                })
                .catch(e => {
                    Swal.fire(
                        "Ups!, Ocurrio un error",
                        "Revise su conexión a internet y vuelva a intentar",
                        "error"
                    );

                    setTimeout(function() {
                        $("#cerrar_modal").click();
                    }, 1000);
                });
               



        },



    },
    async mounted() {


        this.getNovedad();





    }
}
</script>
