<template>
    <div class="page-content">

<!--  ABRE MODAL LEGAJO -->

<div id="info_legajo" style="overflow-y:scroll;">
    <div id="header_legajo">
        <button id="cerrar_legajo" style="position: absolute;right: 20px;margin-top: 3px;background-color:red;font-weight: bold;color:#fff;">CERRAR INFORME</button>
    </div>
    <table width="100%">
        <tr>
            <td width="210px"><center style="padding: 10px;"><div id="legajo_foto"></div></center></td>
            <td valign="top" style="padding: 10px;">
                <table width="100%">
                    
                    <tr>
                        <td width="200px">Nombre y Apellido</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>DNI</td>
                        <td></td>
                    </tr>

                     <tr>
                        <td>Fecha de Nacimiento</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Fecha de Ingreso</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Categoría</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Domicilio</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Provincia</td>
                        <td></td>
                    </tr>

                     <tr>
                        <td>Ciudad</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Nacionalidad</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Tel. Celular</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Email</td>
                        <td></td>
                    </tr>

                   

                </table>
            </td>

            <td width="700px" valign="top" style="padding: 10px;">
                
                <table width="100%">
                    
                    <tr>
                        <td align="center">DIAS</td>
                        <td align="center">VACUNAS</td>
                        <td align="center">CMA</td>
                    </tr>

                    <tr>
                        <td align="center"><div class="circulo"></div></td>
                        <td align="center"><div class="circulo"></div></td>
                        <td align="center"><div class="circulo"></div></td>
                    </tr>

                </table>

                <br><br>

                <table width="100%">
                    
                    <tr>
                        <td width="200px">OPERACIÓN</td><td></td>
                    </tr>

                    <tr>
                        <td>RESPONSABLE</td><td></td>
                    </tr>

                   
                    
                </table>

            </td>
        </tr>
    </table>

   <!-- TABLA DE NOVEDADES -->
<!--
<div style="margin-top:30px;">
     <table
                id="novedades_legajo"
                class="table table-theme table-row v-middle"
                data-plugin="dataTable"
              >
                <thead>
                  <tr>
                    <th><span class="text-muted">Status</span></th>
                    <th><span class="text-muted">legajo</span></th>
                    <th><span class="text-muted">Nombre</span></th>
                   
                    <th><span class="text-muted">Operación</span></th>

                    
                    <th><span class="text-muted">Novedad</span></th>
                    <th><span class="text-muted">Detalle</span></th>
                    <th><span class="text-muted">Horas</span></th>
                    
                    <th><span class="text-muted">Desde</span></th>
                    <th><span class="text-muted">Hasta</span></th>
                    <th><span class="text-muted">Días</span></th>
                    <th><span class="text-muted">Destino</span></th>
                    <th><span class="text-muted">Responsable</span></th>
                    <th><span class="text-muted">Doc</span></th>
                    <th><span class="text-muted">Obs</span></th>
                
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="caso in novedades" :key="caso.legajo">
                        

                        <td v-if="caso.status=='En espera'">
                            <span  class="badge text-uppercase badge-warning">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        <td v-if="caso.status=='Resuelto'">
                            <span  class="badge text-uppercase badge-success">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        <td v-if="caso.status=='Observado'">
                            <span  class="badge text-uppercase badge-info">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        <td v-if="caso.status=='Rechazado'">
                            <span  class="badge text-uppercase badge-danger">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        <td v-if="caso.status=='-'">
                             <span  class="badge text-uppercase badge-warning">
                             En espera
                            </span>
                        </td>

                      
                         <td class="text-sm text-wrap">{{ caso.legajo }}</td>
                        
                        <td class="text-sm text-wrap">{{ caso.cuil }}</td>
                        <td class="text-sm text-wrap">{{ caso.operacion_raiz }}</td>
                        
                        <td class="text-sm text-wrap">{{ caso.novedad }}</td>
                        <td class="text-sm text-wrap">{{ caso.detalle }}</td>
                        <td class="text-sm text-wrap">{{ caso.horas }}</td>
                       
                        <td class="text-sm text-wrap">{{ caso.desde }}</td>
                        <td class="text-sm text-wrap">{{ caso.hasta }}</td>
                        <td class="text-sm text-wrap">{{ caso.dias }}</td>
                        <td class="text-sm text-wrap">{{ caso.destino }}</td>
                        <td class="text-sm text-wrap">{{ caso.responsable }}</td>
                       

                        <td class="text-sm text-wrap" v-if="caso.certificado=='yes'"><button type="button" class="btn btn-secondary btn-xs ml-2" v-on:click.stop.prevent="openWindow(caso.fireId)">Ver Certificado</button></td>
                        
                        <td class="text-sm text-wrap" v-if="caso.certificado=='yes_a'"><button type="button" class="btn btn-secondary btn-xs ml-2" v-on:click.stop.prevent="openWindow_a(caso.fireId)">Ver Certificado</button></td>

                        <td class="text-sm text-wrap" v-if="caso.certificado=='not'">-</td>

                        <td class="text-sm text-wrap comentario" v-if="caso.observacion=='1'"> 

                          <i :data-fire="caso.fireId" onclick="localStorage.setItem('status_id',$(this).data('fire'))" class="feather icon-mail" @click="mensajes()" style="font-size: 20px;color: green;"></i>

                          <i v-if="caso.noti=='1'" :id="'key'+caso.fireId" class="feather icon-radio" style="font-size: 20px;color: red;"><span style="visibility:hidden;font-size:1px;">Sin responder</span></i>
                          <i v-else></i>

                        </td>
                        
                        <td class="text-sm text-wrap" v-else> - </td>
                       


                       



                    </tr>
                </tbody>
              </table>
</div>-->
   <!-- CIERRA TABLA DE NOVEDADES -->

</div>

<!--  CIERRA MODAL LEGAJO -->


                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                            </p>

                  <div style="position:absolute;right: 80px;margin-top: -67px;">
                    <table>
                           <tr>
                                <td><span class="badge badge-success">Food Service America</span>:</td>
                                <td> <b id="america_count" style="font-size: 17px;margin-left: 10px;">0</b></td>

                                <td><span class="badge badge-success">EN CONVENIO</span>:</td>
                                <td> <b id="ameconv_count" style="font-size: 17px;margin-left: 10px;">0</b></td>

                                <td><span class="badge badge-success">FUERA DE CONVENIO</span>:</td>
                                <td> <b id="ameconvno_count" style="font-size: 17px;margin-left: 10px;">0</b></td>
                            </tr>

                           <tr>
                                <td><span class="badge badge-info">Food Service Catering</span>: </td>
                                <td><b id="catering_count" style="font-size: 17px;margin-left: 10px;">0</b></td>


                                <td><span class="badge badge-info">EN CONVENIO</span>:</td>
                                <td> <b id="catconv_count" style="font-size: 17px;margin-left: 10px;">0</b></td>

                                <td><span class="badge badge-info">FUERA DE CONVENIO</span>:</td>
                                <td> <b id="catconvno_count" style="font-size: 17px;margin-left: 10px;">0</b></td>

                            </tr>

                    </table>
<br>
                    <div><b>PROMEDIO DE LOS ULTIMOS 15 DIAS:</b> <span id="promedio_nomina" style="font-weight: 900;color:green;">0</span></div>
                   </div>       


                   <canvas id="legajosChart" width="400" height="50"></canvas>

                        </div>  
                      </div>
                    </div>
                  </div>    

                 <lista-carga v-if="triggers.load"></lista-carga>

                  <div class="row" v-else>
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table id="empresas" class="table">
                              <thead>
                                <tr>
                                  <th>Perfil</th>
                                  <th>Cod</th>
                                  <th>Legajo</th>
                                  <th>Nombre</th>
                                  <th>Fec. Nacimiento</th>
                                  <th>CUIL</th>
                                  <th>CMA</th>
                                  <th>CMA VTO.</th>
                                  <!--<th>CVACUNA</th>
                                  <th>DOSIS</th>-->
                                  <th>Empleado por</th>
                                  <th>Ingreso</th>
                                  <th>Categoria</th>
                                  
                                  <th>Operación</th>
                                  <th>Empresa Madre</th>
                                  <th>Tipo</th>
                                  <th>Zonal</th>
                                  <th>Regional</th>
                                  <th>Status</th>
                                  <th>Convenio</th>
                                  <th>Accion</th>
                                </tr>
                              </thead>
                              <tbody>
                                
                                <tr v-for="personal in personal" v-bind:key="personal" :class="{'fila-no-distribuido':personal.cod=='-'}">
                                    
                                    <td v-if="personal.avatar=='-'" style="color:#fff;">2<img width="40px" height="40px" style="border: 1px solid #8b8b8b99;object-fit:cover" src="https://thumbs.dreamstime.com/b/perfil-de-usuario-vectorial-avatar-predeterminado-179376714.jpg"></td>
                                    
                                    <td v-else style="color:#fff;">1<img width="40px" height="40px" style="object-fit:cover" :src="personal.avatar"></td>
                                    <td>{{personal.bejerman}}</td>
                                    <!--<td><a href="#" @click="getLegajo(personal.dni)">{{personal.legajo}}</a></td>-->
                                    <td>{{personal.legajo}}</td>
                                    <td>{{personal.apellido}} <br> {{personal.nombre}}</td>

                                    <td>{{personal.nacimiento}}</td>
                                    <td>{{personal.cuil}}</td>

                                    <td v-if="personal.manipulador!='-'"><a :href="personal.manipulador" target="_blank">Abrir carnet</a></td>
                                    <td v-else>-</td>

                                    <td v-if="personal.manipulador_vto!='-'">{{personal.manipulador_vto}}</td>
                                    <td v-else>-</td>

                                   <!-- <td v-if="personal.vacunado!='-'">
                                     <button type="button" class="btn btn-success btn-xs ml-2" @click="vacunas(personal.dni, personal.nombre, personal.apellido, personal.legajo,personal.vacunado)">
                                        Ver vacunas
                                     </button>
                                    </td>
                                                                          
                                    <td v-else>
                                        
                                            <button type="button" class="btn btn-danger btn-xs ml-2" @click="vacunas(personal.dni, personal.nombre, personal.apellido, personal.legajo)">
                                            Cargar vacunas
                                            
                                            </button>

                                    </td>
                                    <td align="center">{{personal.dosis}}</td>-->

                                      <td v-if="personal.razonsocial=='Food Service America'"><span class="badge badge-success">Food Service America</span></td>
                                    <td v-else><span class="badge badge-info">Food Service Catering</span></td>


                                    


                                    <td>{{personal.ingreso}}</td>
                                    <td>{{personal.categoria}}</td>

                                    <td>{{personal.operacion}}</td>
                                     <td v-if="personal.madre=='Food Service America'"><span class="badge badge-success">Food Service America</span></td>
                                   <td v-if="personal.madre=='Food Service Catering'"><span class="badge badge-info">Food Service Catering</span></td>
                                   <td v-if="personal.madre=='-'"><span class="badge badge-danger">Planta</span></td>
                                    <td>{{personal.tipo}}</td>
                                    <td>{{personal.zonal}}</td>
                                    <td>{{personal.regional}}</td>
                                    <td v-if="personal.cod!='-'"><span class="badge badge-success">Distribuido</span></td>
                                    <td v-else><span class="badge badge-danger">Sin distribuir</span></td>
                                    <td>{{personal.convenio}}</td>
                                    <td>
                                        <button v-if="isEditor('Personal')==true" type="button" class="btn btn-primary btn-xs" @click.prevent="abrirModalAdministrar(personal.legajo, personal.nombre, personal.apellido, personal.cod, personal.idDistribucion,personal)">Administrar</button>
                                        <button v-else type="button" class="btn btn-secondary btn-xs" disabled>Administrar</button>

                                        <button v-if="isEditor('Personal')==true" type="button" class="btn btn-danger btn-xs ml-2" @click.prevent="abrirModalLegales(personal.dni, personal.nombre, personal.apellido, personal.legajo)">Enviar a legales</button>
                                         <button v-else type="button" class="btn btn-secondary btn-xs" disabled>Enviar a legales</button>


                                         <button type="button" class="btn btn-secondary btn-xs ml-2" @click="observarPersonalId(personal.dni, personal.nombre, personal.apellido, personal.legajo)">
                                            Observacion
                                            <div class="observacion-existente" v-if="personal.observacion">✓</div>
                                        </button>
                                   
                                    </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                <modal-administrar
                    v-if="modalAdministrar.open" 
                    :empresa="modalOperacionId"
                    :legajoEmpleado="modalAdministrar.legajo"
                    :nombreEmpleado="modalAdministrar.nombre"
                    :apellidoEmpleado="modalAdministrar.apellido"
                    :operacion="modalAdministrar.operacion"
                    :idDistribucion="modalAdministrar.idDistribucion"
                    @cerrar-modal-asignar="refrescarPersonal($event)"
                >
                </modal-administrar>


        <modal-observar
            v-if="modalObservar.open" 
                    :dniEmpleado="modalObservar.dni"
                    :legajoEmpleado="modalObservar.legajo"
                    :nombreEmpleado="modalObservar.nombre"
                    :apellidoEmpleado="modalObservar.apellido"
                   
            @cerrar-modal-observar="refrescarPersonal($event)"
            v-on:keyup.enter="modalObservar.open=false"
        ></modal-observar>


         <modal-vacunas
            v-if="modalVacunas.open" 
                    :dniEmpleado="modalVacunas.dni"
                    :legajoEmpleado="modalVacunas.legajo"
                    :nombreEmpleado="modalVacunas.nombre"
                    :apellidoEmpleado="modalVacunas.apellido"
                    :certificadoEmpleado="modalVacunas.certificado"
                   
            @cerrar-modal-observar="refrescarPersonal($event)"
            v-on:keyup.enter="modalVacunas.open=false"
        ></modal-vacunas>

                <modal-legales
                    v-if="modalLegales.open" 
                    :dniEmpleado="modalLegales.dni"
                    :nombreEmpleado="modalLegales.nombre"
                    :apellidoEmpleado="modalLegales.apellido"
                    :legajoEmpleado="modalLegales.legajo"
                    @cerrar-modal-legales="refrescarPersonal($event)"
                ></modal-legales>

            </div>
     

</template>

<script>

import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
import ListaCarga from '../ListaCarga.vue';
import ModalAdministrar from './ModalAdministrar.vue';
import ModalObservar from './ModalObservar.vue';
import modalVacunas from './ModalVacunas.vue';
import ModalLegales from './ModalLegales';
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    name: 'Personal',
    components: {ListaCarga, ModalAdministrar, ModalLegales,ModalObservar,modalVacunas},
    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Personal',
            descripcion: 'En este modulo usted podra visualizar y administrar todo  el personal de la empresa',
            triggers: {load:true},
            personal: [],
            novedades: [],
             modalObservar: {
                open: false,
                dni: null,
                nombre: null,
                legajo: null,
                apellido: null,
            },
             modalVacunas: {
                open: false,
                dni: null,
                nombre: null,
                legajo: null,
                apellido: null,
                certificado:null
            },
            modalAdministrar: {
                open: false,
                legajo: null,
                nombre: null,
                apellido: null,
                operacion: null,
                idDistribucion: null,
            },
            modalLegales: {
                open: false,
                dni: null,
                nombre: null,
                apellido: null,
            },
        }
    },
    methods: {
        isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
        },
        observarPersonalId(dni, nombre, apellido, legajo){
            this.modalObservar.dni = dni;
            this.modalObservar.nombre = nombre;
            this.modalObservar.apellido = apellido;
            this.modalObservar.legajo = legajo;

            console.log(apellido)
            setTimeout(() => {
                this.modalObservar.open = true;
            }, 200);
        },
        vacunas(dni, nombre, apellido, legajo,certificado){
            

            this.modalVacunas.dni = dni;
            this.modalVacunas.nombre = nombre;
            this.modalVacunas.apellido = apellido;
            this.modalVacunas.legajo = legajo;

            if (certificado!=null) {
                this.modalVacunas.certificado = certificado;
            }
            

            console.log(apellido)
            setTimeout(() => {
                this.modalVacunas.open = true;
            }, 200);
        },
        abrirModalAdministrar(legajo,nombre,apellido,operacion,idDistibucion,empleade){
            console.log(empleade);

            this.modalAdministrar.legajo = legajo;
            this.modalAdministrar.nombre = nombre;
            this.modalAdministrar.apellido = apellido;
            this.modalAdministrar.operacion = (operacion == '-') ? undefined : operacion;
            this.modalAdministrar.idDistribucion = idDistibucion;
            this.modalAdministrar.open = true;
        },
        abrirModalLegales(dni,nombre,apellido,legajo){
            this.modalLegales.dni = dni;
            this.modalLegales.nombre = nombre;
            this.modalLegales.apellido = apellido;
            this.modalLegales.legajo = legajo;
            this.modalLegales.open = true;
        },
        async obtenerPersonal(){
            this.triggers.load = true;
            const axiosrequest1 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
            const axiosrequest2 = axios.get('https://apprrhh-707b9.firebaseio.com/personal.json?orderBy="$key"&limitToLast=7000&print=pretty');
            const axiosrequest3 = axios.get('https://aplicativos.firebaseio.com/distribucion.json?print=pretty');
            const axiosrequest4 = axios.get('https://aplicativos.firebaseio.com/zonal.json?print=pretty');
            const axiosrequest5 = axios.get('https://aplicativos.firebaseio.com/categorias.json?print=pretty');
            const axiosrequest6 = axios.get('https://aplicativos.firebaseio.com/legales.json?print=pretty');
            const axiosrequest7 = axios.get('https://aplicativos.firebaseio.com/sectores.json?print=pretty');

            const axiosrequest8 = axios.get('https://apprrhh-707b9.firebaseio.com/personal_cat.json?orderBy="$key"&limitToLast=7000&print=pretty');
            const axiosrequest9 = axios.get('https://aplicativos.firebaseio.com/observacion_personal.json?print=pretty');
            const axiosrequest10 = axios.get('https://aplicativos.firebaseio.com/manipulacion.json?print=pretty');
            const axiosrequest11 = axios.get('https://aplicativos.firebaseio.com/certificado_vacunacion.json?print=pretty');
            const axiosrequest12 = axios.get('https://aplicativos.firebaseio.com/vacunas_personal.json?print=pretty');
            const axiosrequest13 = axios.get('https://aplicativos.firebaseio.com/perfil.json?print=pretty');
            const regional_dato = axios.get('https://aplicativos.firebaseio.com/regional.json?print=pretty');
            const axiosrequest14 = axios.get('https://aplicativos.firebaseio.com/categorias_cat.json?print=pretty');

            const axiosrequest15 = axios.get('https://auth.foodservice.com.ar/?type=promedio_nomina&dias=15&access_token=1234567');

            await axios.all([axiosrequest1, axiosrequest2, axiosrequest3, axiosrequest4, axiosrequest5, axiosrequest6, axiosrequest7,axiosrequest8,axiosrequest9,axiosrequest10,axiosrequest11,axiosrequest12,axiosrequest13,regional_dato,axiosrequest14,axiosrequest15]).then(
            axios.spread((operaciones, personal, distribucion, zonal, categorias, legales, sectores,personal_cat,observaciones,manipulacion,vacunacion,dosis,avatar,regional_data,categorias_cat,promedio_nomina) => {








console.log(promedio_nomina.data)

const data = promedio_nomina.data;

        // Preparar los datos para el gráfico
        const fechas = data.map(entry => entry.fecha);
        const legajos = data.map(entry => entry.legajos);

        // Calcular el promedio de legajos
        const promedio = legajos.reduce((a, b) => a + b, 0) / legajos.length;

        // Crear el gráfico de líneas
        const ctx = document.getElementById('legajosChart').getContext('2d');
        const legajosChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: fechas,
                datasets: [{
                    label: 'Total personal activo',
                    data: legajos,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                    fill: false
                }]
            },
            options: {
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: 'day',
                            tooltipFormat: 'MMM DD'
                        }
                    },
                    y: {
                        beginAtZero: false
                    }
                }
            }
        });



document.getElementById('promedio_nomina').innerText = promedio.toFixed(0);










                var lista = [];
                var observacion = false;

                


                const result_categoria = (id) => {
                    var categoria = "";
                    $.each(categorias.data, function(clave, value) {

                        if (value.codigo == id) {
                            categoria = value.nombre;
                        }
                    })
                    return categoria;
                }


            const result_categoria_cat = (id) => {
                var categoria = "";
                $.each(categorias_cat.data, function(clave, value) {

                    if (value.codigo == id) {
                        categoria = value.nombre;
                    }
                })
                return categoria;
            }


                const result_distribucion = (id) => {
                    var distri = "";
                    $.each(distribucion.data, function(clave, value) {
                        $.each(value, function(k, v) {
                            if (v.legajo == id) {
                                distri = {
                                    operacion: clave,
                                    idDistribucion: k
                                }                               
                            }
                        })

                    });
                    return distri;
                }

                const result_operacion = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = sectores.data[sector].operacion;
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.name;
                             
                            }
                        })
                    }
                    return operacion;
                }




                const result_madre = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = "-";
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.empresa_madre;
                             
                            }
                        })
                    }
                    return operacion;
                }




const result_tipo = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = "Planta Central";
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.tipo_empresa;
                             
                            }
                        })
                    }
                    return operacion;
                }



                const result_operacion_bejerman = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = 'S'+sectores.data[sector].id;
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.catering_id;
                             
                            }
                        })
                    }
                    return operacion;
                }


                 const result_apropiacion = (id) => {
                    var operacion = "";
                    var apropiacion = "";
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                          
                            apropiacion = sectores.data[sector].id_empresa;

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                             
                                // apropiacion = value.centroapropiacion_id
                                apropiacion = value.id
                            }
                        })
                    }
                    return apropiacion;
                }

                const result_zonal = (id) => {
                    var zonales = "";
                    $.each(zonal.data, function(clave, value) {
                        if (clave == id) {
                            zonales = value.nombre;
                        }
                    })
                    return zonales;
                }


                 const result_manipulador = (dni) => {
                    var manip = "";
                    $.each(manipulacion.data, function(clave, value) {
                        if (clave == dni) {
                            manip = value.link;
                        }
                    })
                    return manip;
                }


                   const result_manipulador_vto = (dni) => {
                    var manip = "";
                    $.each(manipulacion.data, function(clave, value) {
                        if (clave == dni) {
                            manip = value.vto;
                        }
                    })
                    return manip;
                }

                const result_vacunacion = (legajo) => {
                    var vacu = "";
                    $.each(vacunacion.data, function(clave, value) {
                        if (clave == legajo) {
                            vacu = value.certificado;
                        }
                    })
                    return vacu;
                }

                const result_dosis = (dni) => {
                    var dos = "";
                    $.each(dosis.data, function(clave, value) {
                        if (clave == dni) {
                            // console.log("datos obtenidos!")
                           dos = Object.keys(value).length/2
                        }
                    })
                    return dos;
                }


                const result_avatar = (dni) => {
                    var avtr = "";
                    $.each(avatar.data, function(clave, value) {
                        if (clave == dni) {
                            avtr = value.imagen;
                        }
                    })
                    return avtr;
                }

                const reemplazarIndefinido = (string) => {
                    if (string==undefined || string == null || string == '') {
                        return "-"
                    }
                    return string
                }

                const reemplazarIndefinidoDosis = (string) => {
                    if (string==undefined || string == null || string == '') {
                        return "0"
                    }
                    return string
                }


                var america_count=[];
                var catering_count=[];
              
               var regional="";
               
                var ameconv_count = 0;
                var amenoconv_count = 0;

                var catconv_count = 0;
                var catnoconv_count = 0;
                $.each(personal.data, function(clave, valor) {

                     observacion = (observaciones.data[valor.leg_numdoc] != undefined) ? true : false;
                     
                    if(legales.data[valor.leg_numdoc] != null) return true;
                    
                    if (valor.leg_fecegr == null) {

                        let distribucion = result_distribucion(valor.leg_numero);
                        
                          if (regional_data.data!=null) {
                                var key_regional = Object.keys(regional_data.data).filter(key => key == distribucion.operacion);
                        
                                var result_regional = key_regional.reduce((r, k) => r.concat(regional_data.data[k]), []);
                               
                                if (result_regional[0]==null) {
                                    
                                    regional = "-";
                                }else{
                                    regional = result_regional[0].nombre;
                                }
                                }else{
                                regional = '-';
                            }



                            // Verifica si 'sec_descrip' no contiene la palabra 'cct'
                            var palabra = valor.sec_descrip.toLowerCase().includes('cct') ? 'DENTRO DE CONVENIO' : 'FUERA DE CONVENIO';

                           


                            if (palabra=="FUERA DE CONVENIO") {
                                amenoconv_count++;
                            }else{
                                ameconv_count++;
                            }

                             $("#ameconv_count").html(ameconv_count)
                            $("#ameconvno_count").html(amenoconv_count)

                        lista.push({
                            "cod": reemplazarIndefinido(result_apropiacion(distribucion.operacion)),
                            "razonsocial": "Food Service America",
                            "bejerman": reemplazarIndefinido(result_operacion_bejerman(distribucion.operacion)),
                            "legajo": reemplazarIndefinido(valor.leg_numero),
                            "nombre": reemplazarIndefinido(valor.leg_nombre),
                            "apellido": reemplazarIndefinido(valor.leg_apellido),
                            "ingreso": reemplazarIndefinido(valor.leg_fecingr.date.replace(" 00:00:00.000000", "")),
                            "categoria": reemplazarIndefinido(valor.cat_descrip),
                            "nacimiento": reemplazarIndefinido(valor.leg_fecnac.date.replace(" 00:00:00.000000", "")),
                            "cuil": reemplazarIndefinido(valor.leg_cuil),
                            "operacion": reemplazarIndefinido(result_operacion(distribucion.operacion)),
                             "madre": reemplazarIndefinido(result_madre(distribucion.operacion)),
                            "tipo": reemplazarIndefinido(result_tipo(distribucion.operacion)),
                            "idDistribucion": distribucion.idDistribucion,
                            "zonal": reemplazarIndefinido(result_zonal(result_distribucion(valor.leg_numero).operacion)),
                            "dni": valor.leg_numdoc,
                            "observacion":observacion,
                            "manipulador":reemplazarIndefinido(result_manipulador(valor.leg_numdoc)),
                            "manipulador_vto":reemplazarIndefinido(result_manipulador_vto(valor.leg_numdoc)),
                            "vacunado":reemplazarIndefinido(result_vacunacion(valor.leg_numero)),
                            "dosis":reemplazarIndefinidoDosis(result_dosis(valor.leg_numdoc)),
                            "avatar": reemplazarIndefinido(result_avatar(valor.leg_numdoc)),
                            "regional":regional,
                            "convenio":reemplazarIndefinido(valor.sec_descrip)
                        })

                        america_count.push(1);
                    }
                });

                $.each(personal_cat.data, function(clave, valor) {

                     observacion = (observaciones.data[valor.leg_numdoc] != undefined) ? true : false;
                    if(legales.data[valor.leg_numdoc] != null) return true;

                    if (valor.leg_fecegr == null) {
                        let distribucion = result_distribucion(valor.leg_numero);

                        if (regional_data.data!=null) {
                                var key_regional = Object.keys(regional_data.data).filter(key => key == distribucion.operacion);
                        
                                var result_regional = key_regional.reduce((r, k) => r.concat(regional_data.data[k]), []);
                              
                                if (result_regional[0]==null) {
                                    
                                    regional = "-";
                                }else{
                                    regional = result_regional[0].nombre;
                                }
                                }else{
                                regional = '-';
                            }


                            if (valor.sec_descrip=="FUERA DE CONVENIO") {
                                catnoconv_count++;
                            }else{
                                catconv_count++;
                            }

                             $("#catconv_count").html(catconv_count)
                            $("#catconvno_count").html(catnoconv_count)

                        lista.push({
                            "cod": reemplazarIndefinido(result_apropiacion(distribucion.operacion)),
                            "razonsocial": "Food Service Catering",
                            "bejerman": reemplazarIndefinido(result_operacion_bejerman(distribucion.operacion)),
                            "legajo": reemplazarIndefinido(valor.leg_numero),
                            "nombre": reemplazarIndefinido(valor.leg_nombre),
                            "apellido": reemplazarIndefinido(valor.leg_apellido),
                            "ingreso": reemplazarIndefinido(valor.leg_fecingr.date.replace(" 00:00:00.000000", "")),
                            "categoria": reemplazarIndefinido(valor.cat_descrip),
                            "nacimiento": reemplazarIndefinido(valor.leg_fecnac.date.replace(" 00:00:00.000000", "")),
                            "cuil": reemplazarIndefinido(valor.leg_cuil),
                            "operacion": reemplazarIndefinido(result_operacion(distribucion.operacion)),
                             "madre": reemplazarIndefinido(result_madre(distribucion.operacion)),
                             "tipo": reemplazarIndefinido(result_tipo(distribucion.operacion)),
                            "idDistribucion": distribucion.idDistribucion,
                            "zonal": reemplazarIndefinido(result_zonal(result_distribucion(valor.leg_numero).operacion)),
                            "dni": valor.leg_numdoc,
                            "observacion":observacion,
                              "manipulador":reemplazarIndefinido(result_manipulador(valor.leg_numdoc)),
                               "manipulador_vto":reemplazarIndefinido(result_manipulador_vto(valor.leg_numdoc)),
                               "vacunado":reemplazarIndefinido(result_vacunacion(valor.leg_numero)),
                                "dosis":reemplazarIndefinidoDosis(result_dosis(valor.leg_numdoc)),
                                 "avatar": reemplazarIndefinido(result_avatar(valor.leg_numdoc)),
                                 "regional":regional,
                                 "convenio":reemplazarIndefinido(valor.sec_descrip)
                        })
                        catering_count.push(1);
                    }
                });


                $("#america_count").html(america_count.length)
                $("#catering_count").html(catering_count.length)
               

                // console.log(lista);
                this.personal = lista;
                this.triggers.load = false;

                })).then(() => {
                           var tabla =  $('#empresas').DataTable({
                                "language": {
                                    "decimal": ",",
                                    "thousands": ".",
                                    "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                    "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                    "infoPostFix": "",
                                    "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                                    "loadingRecords": "Cargando...",
                                    "lengthMenu": "Mostrar _MENU_ registros",
                                    "paginate": {
                                        "first": "Primero",
                                        "last": "Último",
                                        "next": "Siguiente",
                                        "previous": "Anterior"
                                    },
                                    "processing": "Procesando...",
                                    "search": "Buscar:",
                                    "searchPlaceholder": "Término de búsqueda",
                                    "zeroRecords": "No se encontraron resultados",
                                    "emptyTable": "Ningún dato disponible en esta tabla",
                                    "aria": {
                                        "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                        "sortDescending": ": Activar para ordenar la columna de manera descendente"
                                    },
                                },
                                "scrollX": false, 
                                "search": {regex:true},
                                dom: 'Bfrtip',
                                "order": [
                                    [16, "desc"]
                                ],
                                responsive: false,
                                nowrap: false,
                                buttons: [
                                    'copy',
                                    'excel',
                                    'csv',
                                    'pdf'
                                ]
                            });








                });

                
        },
        refrescarPersonal(seReasigno){
            this.modalAdministrar.open = false;
            this.modalLegales.open = false;
            this.modalObservar.open = false;
            this.modalVacunas.open = false;
            
            if (seReasigno) {
                $('#empresas').dataTable().fnDestroy();
                this.obtenerPersonal();
            }

             
        },

         getLegajo(legajo){
               
//$('#novedades_legajo').dataTable().fnDestroy();
 //$("body").css("overflow","hidden");

 $("#info_legajo").slideDown();



 $.get( "https://auth.foodservice.com.ar/?type=login&user="+legajo+"&access_token=1234567", function( data ) {
  var json = ( data );

  console.log(json)
  
});


  $("#cerrar_legajo").click(function() {
  
                         $("#info_legajo").slideUp();
                          //$("body").css("overflow","scroll");
  
  
                      })
/*
  this.novedades = [];
            this.triggers.load = true;

            axios.get("https://auth.foodservice.com.ar/index_test.php?type=novedades_legajo&legajo="+legajo+"&access_token=1234567")
                .then((response) => {
                    var vue = this;
                    var obs = 0;




                    $.each(response.data, (id, item) => {
                        let caso = item;

                        var certif_u = '-';
                        if (caso != null) {

                            if (caso.observacion != undefined) {

                                obs = 1;
                            } else {
                                obs = 0;
                            }




                            if (caso.certificado_update != undefined) {

                                $.each(caso.certificado_update, function(clab, itb) {

                                    certif_u = itb.certificado;



                                });

                                cert_c = certif_u;
                            }




                            const reemplazarIndefinido = (string) => {
                                if (string == undefined || string == null || string == '') {
                                    return "-"
                                }
                                return string
                            }



                            var fechaInicio = new Date(caso.desde).getTime();
                            var fechaFin = new Date(caso.hasta).getTime();

                            var diff = fechaFin - fechaInicio;
                            var bejerman = "-";
                            if (caso.bejerman_id) {
                                bejerman = caso.bejerman_id;
                            }




                            vue.novedades.push({
                                fireId: id,
                                desde: caso.desde,
                                destino: caso.operacion,
                                detalle: caso.detalle,
                                fecha: caso.fecha,
                                fecha_novedad: caso.fecha_novedad,
                                hasta: caso.hasta,
                                certificado: caso.certificado,
                                legajo: caso.legajo,
                                nombre: caso.nombre,
                                novedad: caso.novedad,
                                horas: reemplazarIndefinido(caso.horas),
                                noti: reemplazarIndefinido(caso.obs),
                                bejerman_id: bejerman,
                                operacion_raiz: caso.operacion_raiz,
                                responsable: caso.responsable,
                                responsable_dni: caso.responsable_dni,
                                status: reemplazarIndefinido(caso.status),
                                observacion: obs,
                                statusMensaje: caso.status,
                                dias: (diff / (1000 * 60 * 60 * 24)) + 1
                            });




                        }
                    });



                })
                .then(() => {

                  this.triggers.load = false;
                  this.$nextTick(()=>{
                 
   
                        
  
                      $('#novedades_legajo').DataTable({
                          "language": {
                              "decimal": ",",
                              "thousands": ".",
                              "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                              "infoEmpty": "Mostrando registros del0 al0 de un total de0 registros",
                              "infoPostFix": "",
                              "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                              "loadingRecords": "Cargando...",
                              "lengthMenu": "Mostrar _MENU_ registros",
                              "paginate": {
                                  "first": "Primero",
                                  "last": "Último",
                                  "next": "Siguiente",
                                  "previous": "Anterior"
                              },
                              "processing": "Procesando...",
                              "search": "Buscar:",
                              "searchPlaceholder": "Término de búsqueda",
                              "zeroRecords": "No se encontraron resultados",
                              "emptyTable": "Ningún dato disponible en esta tabla",
                              "aria": {
                                  "sortAscending": ": Activar para ordenar la columna de manera ascendente",
                                  "sortDescending": ": Activar para ordenar la columna de manera descendente"
                              },
                          },
                          "scrollX": false,
                          "search": {
                              regex: true
                          },
                          dom: 'Bfrtip',
                          responsive: false,
                          nowrap: false,
                          "initComplete": function(settings, json) {
  
                              setTimeout(function() {
                                  console.log("completo");
                                  $('#novedades_legajo').DataTable().order([7, 'desc']).draw();
                              }, 2000)
  
  
                              // call your function here
                          },
                          buttons: [
  
                              'excel',
                              'csv',
  
                              {
                                  text: 'En espera',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("En espera").draw();
                                      table.columns(16).search("").draw();
                                  }
                              },
                              {
                                  text: 'Observado',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("Observado").draw();
                                      table.columns(16).search("").draw();
                                  }
                              },
                              {
                                  text: 'Rechazado',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("Rechazado").draw();
                                      table.columns(16).search("").draw();
                                  }
                              }, {
                                  text: 'Resuelto',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("Resuelto").draw();
                                      table.columns(16).search("").draw();
                                  }
                              }, {
                                  text: 'Sin responder',
                                  className: 'btn btn-danger',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(16).search("Sin responder").draw();
                                  }
                              },
                              {
                                  text: 'Todos',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("").draw();
                                      table.columns(6).search("").draw();
                                      table.columns(16).search("").draw();
                                  }
                              }
                          ]
                      })
  
  
  
                      const diasEntreFechas = (desde, hasta) => {
  
                          var dia_actual = desde;
                          var fechas = [];
                          while (dia_actual.isSameOrBefore(hasta)) {
                              fechas.push(dia_actual.format('YYYY-MM-DD'));
                              dia_actual.add(1, 'days');
                          }
                          return fechas;
                      };
  
                      $("#buscar_legajo").click(function() {
  
                          console.log($("#desde").val() + "hasta " + $("#hasta").val())
                          var filtro = "";
                          var desde = moment($("#desde").val());
                          var hasta = moment($("#hasta").val());
                          var results = diasEntreFechas(desde, hasta);
  
  
                          $.each(results, function(key, value) {
                              filtro += value + "|";
                          })
                          var table = $('#novedades_legajo').DataTable();
                          console.log(filtro.substring(0, filtro.length - 1));
                          table.column(10).search(filtro.substring(0, filtro.length - 1), true, false).draw();
  
  
                      })

                  })


                })

        
*/
                      






        }
    },
    mounted() {
        this.obtenerPersonal();
    },
};
</script>

<style scoped>
.fila-no-distribuido {
    /* color: red; */
    /* text-shadow: rgba(255, 0, 0, 0.603) 0px 0px 1px; */
    background: radial-gradient(#ff00001a, transparent 91%);
}
.observacion-existente {
    background: #10b759;
    border-radius: 50%;
    padding: 3px 4px;
    padding-top: 4px;
    width: 16px;
    height: 16px;
    position: absolute;
    transform: translate(60px, -20px);
}


#info_legajo {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 9999999;
    padding: 40px;
    left: 0;
    top: 0;
    display: none;
}

#info_legajo td{
    border: 1px solid #ccc;
}

#legajo_foto{
    width: 300px;
    height: 300px;
    background-color: #ccc;
}

.circulo {
    width: 100px;
    height: 100px;
    background: #b7b5b5;
    border-radius: 50%;
}

#novedades_legajo td{
    font-size: 10px;
}

#header_legajo{
    width: 100%;
    background-color: #7e86ed;
    height: 37px;
    position: fixed;
    left: 0;
    top: 0;
}
</style>